import React from 'react';
import FootballFixtures from './components/FootballFixtures';

function App() {
  return (
    <div className="App">
      <FootballFixtures />
    </div>
  );
}

export default App;
