import React, { useState, useEffect } from 'react';
import { format } from 'date-fns';
import { 
  Select, MenuItem, FormControl, InputLabel,
  Card, CardContent, CardHeader,
  Table, TableBody, TableCell, TableHead, TableRow,
  Typography, Button, Box, LinearProgress,
  Popover, Tabs, Tab
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import InfoIcon from '@mui/icons-material/Info';

const API_ENDPOINT = 'https://dam1un14l8.execute-api.eu-west-2.amazonaws.com/default/analysisBackend';

const allLeagues = {
    "Austria": [
        {
            "id": 218,
            "name": "Bundesliga",
            "type": "League"
        },
        {
            "id": 219,
            "name": "2. Liga",
            "type": "League"
        }
    ],
    "Belgium": [
        {
            "id": 144,
            "name": "Jupiler Pro League",
            "type": "League"
        },
        {
            "id": 145,
            "name": "Challenger Pro League",
            "type": "League"
        }
    ],
    "Croatia": [
        {
            "id": 210,
            "name": "HNL",
            "type": "League"
        }
    ],
    "Cyprus": [
        {
            "id": 318,
            "name": "1. Division",
            "type": "League"
        }
    ],
    "Czech-Republic": [
        {
            "id": 345,
            "name": "Czech Liga",
            "type": "League"
        }
    ],
    "Denmark": [
        {
            "id": 119,
            "name": "Superliga",
            "type": "League"
        },
        {
            "id": 120,
            "name": "1. Division",
            "type": "League"
        }
    ],
    "Egypt": [
        {
            "id": 233,
            "name": "Premier League",
            "type": "League"
        }
    ],
    "England": [
        {
            "id": 39,
            "name": "Premier League",
            "type": "League"
        },
        {
            "id": 40,
            "name": "Championship",
            "type": "League"
        }
    ],
    "Estonia": [
        {
            "id": 328,
            "name": "Esiliiga A",
            "type": "League"
        }
    ],
    "France": [
        {
            "id": 61,
            "name": "Ligue 1",
            "type": "League"
        },
        {
            "id": 62,
            "name": "Ligue 2",
            "type": "League"
        }
    ],
    "Germany": [
        {
            "id": 78,
            "name": "Bundesliga",
            "type": "League"
        },
        {
            "id": 79,
            "name": "2. Bundesliga",
            "type": "League"
        }
    ],
    "Greece": [
        {
            "id": 197,
            "name": "Super League 1",
            "type": "League"
        }
    ],
    "Hungary": [
        {
            "id": 271,
            "name": "NB I",
            "type": "League"
        },
        {
            "id": 272,
            "name": "NB II",
            "type": "League"
        }
    ],
    "Italy": [
        {
            "id": 135,
            "name": "Serie A",
            "type": "League"
        },
        {
            "id": 136,
            "name": "Serie B",
            "type": "League"
        }
    ],
    "Japan": [
        {
            "id": 98,
            "name": "J1 League",
            "type": "League"
        }
    ],
    "Latvia": [
        {
            "id": 364,
            "name": "1. Liga",
            "type": "League"
        },
        {
            "id": 365,
            "name": "Virsliga",
            "type": "League"
        }
    ],
    "Lithuania": [
        {
            "id": 361,
            "name": "1 Lyga",
            "type": "League"
        },
        {
            "id": 362,
            "name": "A Lyga",
            "type": "League"
        }
    ],
    "Malta": [
        {
            "id": 393,
            "name": "Premier League",
            "type": "League"
        },
        {
            "id": 392,
            "name": "Challenge League",
            "type": "League"
        }
    ],
    "Mexico": [
        {
            "id": 262,
            "name": "Liga MX",
            "type": "League"
        }
    ],
    "Netherlands": [
        {
            "id": 88,
            "name": "Eredivisie",
            "type": "League"
        },
        {
            "id": 89,
            "name": "Eerste Divisie",
            "type": "League"
        }
    ],
    "Norway": [
        {
            "id": 103,
            "name": "Eliteserien",
            "type": "League"
        },
        {
            "id": 104,
            "name": "1. Division",
            "type": "League"
        }
    ],
    "Poland": [
        {
            "id": 106,
            "name": "Ekstraklasa",
            "type": "League"
        }
    ],
    "Portugal": [
        {
            "id": 94,
            "name": "Primeira Liga",
            "type": "League"
        },
        {
            "id": 95,
            "name": "Segunda Liga",
            "type": "League"
        }
    ],
    "Romania": [
        {
            "id": 283,
            "name": "Liga I",
            "type": "League"
        }
    ],
    "Saudi-Arabia": [
        {
            "id": 307,
            "name": "Pro League",
            "type": "League"
        }
    ],
    "Scotland": [
        {
            "id": 179,
            "name": "Premiership",
            "type": "League"
        }
    ],
    "Serbia": [
        {
            "id": 286,
            "name": "Super Liga",
            "type": "League"
        }
    ],
    "Slovakia": [
        {
            "id": 332,
            "name": "Super Liga",
            "type": "League"
        }
    ],
    "South-Africa": [
        {
            "id": 288,
            "name": "Premier Soccer League",
            "type": "League"
        }
    ],
    "Spain": [
        {
            "id": 140,
            "name": "La Liga",
            "type": "League"
        },
        {
            "id": 141,
            "name": "Segunda Divisi\u00f3n",
            "type": "League"
        }
    ],
    "Sweden": [
        {
            "id": 113,
            "name": "Allsvenskan",
            "type": "League"
        },
        {
            "id": 114,
            "name": "Superettan",
            "type": "League"
        }
    ],
    "Switzerland": [
        {
            "id": 207,
            "name": "Super League",
            "type": "League"
        },
        {
            "id": 208,
            "name": "Challenge League",
            "type": "League"
        }
    ],
    "Turkey": [
        {
            "id": 203,
            "name": "S\u00fcper Lig",
            "type": "League"
        }
    ],
    "USA": [
        {
            "id": 253,
            "name": "Major League Soccer",
            "type": "League"
        }
    ],
    "Wales": [
        {
            "id": 110,
            "name": "Premier League",
            "type": "League"
        }
    ],
    "World": [
        {
            "id": 2,
            "name": "UEFA Champions League",
            "type": "Cup"
        },
        {
            "id": 3,
            "name": "UEFA Europa League",
            "type": "Cup"
        },
        {
            "id": 848,
            "name": "UEFA Europa Conference League",
            "type": "Cup"
        }
    ]
};

const ColorBar = ({ value }) => (
    <Box sx={{ width: '100%', mr: 1 }}>
        <LinearProgress variant="determinate" value={value * 100} 
            sx={{
                height: 10,
                borderRadius: 5,
                '& .MuiLinearProgress-bar': {
                    borderRadius: 5,
                    backgroundColor: 
                        value < 0.2 ? 'yellow' :
                        value < 0.4 ? 'orange' :
                        value < 0.6 ? 'orange' :
                        value < 0.8 ? 'red' : 'red',
                },
            }}
        />
    </Box>
);

const PredictedScore = ({ predictedScore }) => (
    <Box sx={{ 
        fontWeight: 'bold',
        fontSize: '2rem',
        color: 'black',
        textAlign: 'center',
    }}>
        {predictedScore}
        <Typography variant="body2" sx={{ marginTop: '0.2rem', fontSize: '0.9rem', fontStyle: 'italic' }}>
            Predicted Goals
        </Typography>
    </Box>
);

const BTTSGraph = ({ value }) => (
    <Box sx={{ position: 'relative', width: '100%', height: 20, backgroundColor: '#e0e0e0', borderRadius: 10, overflow: 'hidden' }}>
        <Box sx={{ 
            position: 'absolute', 
            top: 0, 
            left: 0, 
            height: '100%', 
            width: `${value * 100}%`, 
            backgroundColor: 'blue',
            borderRadius: 10
        }} />
        <Typography variant="caption" sx={{ 
            position: 'absolute', 
            top: '50%', 
            left: '50%', 
            transform: 'translate(-50%, -50%)',
            color: 'white',
            fontWeight: 'bold'
        }}>
            {(value * 100).toFixed(2)}%
        </Typography>
    </Box>
);

const PerformanceDifferenceGraph = ({ value }) => {
    const absValue = Math.abs(value);
    const maxValue = 1;
    const percentage = (absValue / maxValue) * 50;

    return (
        <Box sx={{ position: 'relative', width: '100%', height: 24, backgroundColor: '#e0e0e0', borderRadius: 12, overflow: 'hidden', display: 'flex', alignItems: 'center' }}>
            <Box sx={{ position: 'absolute', left: '50%', width: 2, height: '100%', backgroundColor: '#9e9e9e', zIndex: 1 }} />
            <Box sx={{ 
                position: 'absolute',
                top: 0,
                [value < 0 ? 'right' : 'left']: '50%',
                height: '100%',
                width: `${percentage}%`,
                backgroundColor: value < 0 ? 'red' : 'green',
                borderRadius: 12
            }} />
            <Typography variant="caption" sx={{ 
                position: 'absolute', 
                top: '50%', 
                left: '50%', 
                transform: 'translate(-50%, -50%)',
                zIndex: 2,
                fontWeight: 'bold'
            }}>
                {value.toFixed(2)}
            </Typography>
        </Box>
    );
};

const TeamInfo = ({ team, isHome }) => (
    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: isHome ? 'flex-end' : 'flex-start', flexDirection: 'column' }}>
        <Typography variant="h6" sx={{ fontSize: '1.5rem', fontWeight: 'bold', textAlign: isHome ? 'right' : 'left' }}>
            {team.team_name}
        </Typography>
        <img src={team.team_logo} alt={`${team.team_name} logo`} style={{ width: 48, height: 48 }} />
        <Typography variant="body2" sx={{ textAlign: isHome ? 'right' : 'left', marginTop: '0.5rem', fontSize: '1rem' }}>
            {team.total_points} pts (Ansatz points)
        </Typography>
    </Box>
);

const BetSuggestionPopup = ({ bestBet, anchorEl, onClose, fixtureId }) => (
  <Popover
    open={Boolean(anchorEl)}
    anchorEl={anchorEl}
    onClose={() => onClose(fixtureId)}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
  >
    <Box sx={{ p: 2 }}>
      <Typography variant="h6">Best Bet Suggestion</Typography>
      {bestBet ? (
        <>
          <Typography>Recommendation: {bestBet.recommendation}</Typography>
          <Typography>Value: {bestBet.value}</Typography>
          <Typography>Odds: {bestBet.odds}</Typography>
        </>
      ) : (
        <Typography>No bet suggestion available for this fixture.</Typography>
      )}
    </Box>
  </Popover>
);

export default function FootballFixtures() {
    const [selectedCountry, setSelectedCountry] = useState("England");
    const [selectedLeague, setSelectedLeague] = useState("Premier League");
    const [expandedFixture, setExpandedFixture] = useState(null);
    const [fixtures, setFixtures] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const [betAnchors, setBetAnchors] = useState({});
    const [activeTab, setActiveTab] = useState(0);
    //const [currentBestBet, setCurrentBestBet] = useState(null);

    useEffect(() => {
        fetchFixtures(selectedCountry, selectedLeague);
    }, [selectedCountry, selectedLeague]);

    const fetchFixtures = async (country, league) => {
        setIsLoading(true);
        setError(null);
        try {
            const response = await fetch(`${API_ENDPOINT}?country=${country}&league=${league}`);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            if (data && Array.isArray(data.items)) {
                setFixtures(data.items);
            } else {
                console.error('Unexpected data format:', data);
                setError('Received unexpected data format from the server.');
                setFixtures([]);
            }
        } catch (error) {
            setError('Failed to fetch fixtures. Please try again later.');
            console.error('Error fetching fixtures:', error);
            setFixtures([]);
        } finally {
            setIsLoading(false);
        }
    };

    const handleCountryChange = (country) => {
        setSelectedCountry(country);
        setSelectedLeague(allLeagues[country][0].name);
    };

    const handleLeagueChange = (league) => {
        setSelectedLeague(league);
    };

    const toggleFixtureExpansion = (fixtureId) => {
        setExpandedFixture(expandedFixture === fixtureId ? null : fixtureId);
    };

    const calculateBTTS = (home, away) => {
        return ((home?.probability_to_score || 0) * (away?.probability_to_score || 0));
    };

    const calculatePerformanceDifference = (home, away) => {
        return ((home?.home_performance || 0) - (away?.away_performance || 0));
    };

    const handleBetInfoClick = (event, fixtureId) => {
        event.stopPropagation();
        setBetAnchors(prev => ({
            ...prev,
            [fixtureId]: event.currentTarget
        }));
    };

    const handleBetInfoClose = (fixtureId) => {
        setBetAnchors(prev => {
            const newAnchors = {...prev};
            delete newAnchors[fixtureId];
            return newAnchors;
        });
    };

    const handleTabChange = (event, newValue) => {
        setActiveTab(newValue);
    };

    const renderPastFixtures = (fixtures, teamName = null) => {
        if (!fixtures || fixtures.length === 0) {
            return <Typography>No past fixtures available</Typography>;
        }

        const getScoreColor = (homeScore, awayScore, homeTeam, awayTeam) => {
            if (!teamName) return 'grey.500'; // For head to head, use grey

            if (teamName === homeTeam) {
                if (homeScore > awayScore) return 'success.main';
                if (homeScore < awayScore) return 'error.main';
                return 'grey.500';
            } else if (teamName === awayTeam) {
                if (awayScore > homeScore) return 'success.main';
                if (awayScore < homeScore) return 'error.main';
                return 'grey.500';
            }
            return 'grey.500'; // Default case
        };

        return (
            <Box sx={{ width: '100%', overflowX: 'auto' }}>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>Date</TableCell>
                            <TableCell>Home</TableCell>
                            <TableCell>Score</TableCell>
                            <TableCell>Away</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {fixtures.map((fixture, index) => (
                            <TableRow key={index}>
                                <TableCell>{format(new Date(fixture.date), 'dd MMM yyyy')}</TableCell>
                                <TableCell>{fixture.teams.home}</TableCell>
                                <TableCell>
                                    <Box
                                        sx={{
                                            backgroundColor: getScoreColor(fixture.scores.home, fixture.scores.away, fixture.teams.home, fixture.teams.away),
                                            color: 'white',
                                            padding: '2px 6px',
                                            borderRadius: '4px',
                                            display: 'inline-block'
                                        }}
                                    >
                                        {fixture.scores.home} - {fixture.scores.away}
                                    </Box>
                                </TableCell>
                                <TableCell>{fixture.teams.away}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </Box>
        );
    };

    const renderNextFixture = (team) => {
        if (!team.Next_Fix_Date || !team.Next_Fix_Type || !team.Next_League || !team.Next_Opp) {
            return <Typography>No upcoming fixture information available</Typography>;
        }

        const nextFixDate = new Date(team.Next_Fix_Date);
        const formattedDate = format(nextFixDate, 'dd MMMM yyyy HH:mm');
        const isHome = team.Next_Fix_Type.toLowerCase() === 'home';

        return (
            <Box sx={{ mt: 2, p: 2, border: '1px solid #e0e0e0', borderRadius: 2 }}>
                <Typography variant="h6" gutterBottom>Next Fixture</Typography>
                <Typography>
                    <strong>Date:</strong> {formattedDate}
                </Typography>
                <Typography>
                    <strong>{isHome ? 'Home' : 'Away'}</strong> vs {team.Next_Opp}
                </Typography>
                <Typography>
                    <strong>League:</strong> {team.Next_League}
                </Typography>
            </Box>
        );
    };

    return (
        <Box sx={{ maxWidth: 1200, margin: 'auto', padding: 2 }}>
            <Typography variant="h4" component="h1" gutterBottom>
                Football Fixtures
            </Typography>
            
            <Box sx={{ display: 'flex', gap: 2, mb: 2 }}>
                <FormControl fullWidth>
                    <InputLabel id="country-select-label">Country</InputLabel>
                    <Select
                        labelId="country-select-label"
                        value={selectedCountry}
                        label="Country"
                        onChange={(e) => handleCountryChange(e.target.value)}
                    >
                        {Object.keys(allLeagues).map((country) => (
                            <MenuItem key={country} value={country}>
                                {country}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                
                <FormControl fullWidth>
                    <InputLabel id="league-select-label">League</InputLabel>
                    <Select
                        labelId="league-select-label"
                        value={selectedLeague}
                        label="League"
                        onChange={(e) => handleLeagueChange(e.target.value)}
                    >
                        {allLeagues[selectedCountry].map((league) => (
                            <MenuItem key={league.id} value={league.name}>
                                {league.name}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Box>

            {isLoading && <LinearProgress />}
            {error && (
                <Typography color="error" sx={{ mb: 2 }}>
                    {error}
                </Typography>
            )}
            
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                {Array.isArray(fixtures) && fixtures.length > 0 ? fixtures.map((fixture) => (
                    <Card key={fixture.fixture_id}>
                        <CardHeader
                            sx={{ cursor: 'pointer' }}
                            onClick={() => toggleFixtureExpansion(fixture.fixture_id)}
                            title={
                                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <Box sx={{ flex: 2, display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                                        <TeamInfo team={fixture.home} isHome={true} />
                                    </Box>
                                    <Box sx={{ flex: 1, display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                                        <PredictedScore predictedScore={`${fixture.home.predicted_goals} - ${fixture.away.predicted_goals}`} />
                                        {fixture.best_bet && fixture.best_bet.length > 0 && (
                                            <Button
                                                onClick={(e) => handleBetInfoClick(e, fixture.fixture_id)}
                                                sx={{ minWidth: 'auto', p: 0.5 }}
                                            >
                                                <InfoIcon fontSize="small" />
                                            </Button>
                                        )}
                                    </Box>
                                    <Box sx={{ flex: 2, display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                                        <TeamInfo team={fixture.away} isHome={false} />
                                    </Box>
                                </Box>
                            }
                            action={
                                <Button onClick={() => toggleFixtureExpansion(fixture.fixture_id)}>
                                    {expandedFixture === fixture.fixture_id ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                </Button>
                            }
                        />
                        {fixture.best_bet && fixture.best_bet.length > 0 && (
                            <BetSuggestionPopup
                                bestBet={fixture.best_bet[0]}
                                anchorEl={betAnchors[fixture.fixture_id]}
                                onClose={handleBetInfoClose}
                                fixtureId={fixture.fixture_id}
                            />
                        )}
                        {expandedFixture === fixture.fixture_id && (
                            <CardContent>
                                <Typography variant="body2" gutterBottom>
                                    <strong>Date:</strong> {format(new Date(fixture.date), 'dd MMMM yyyy HH:mm')}
                                </Typography>
                                <Tabs value={activeTab} onChange={handleTabChange} sx={{ mb: 2 }}>
                                    <Tab label="Statistics" />
                                    <Tab label="Last 5 Fixtures" />
                                    <Tab label="Head to Head" />
                                </Tabs>
                                {activeTab === 0 && (
                                    <Box>
                                        <Box sx={{ display: 'flex', gap: 2, mb: 2 }}>
                                            <Box sx={{ flex: 1 }}>
                                                <Typography variant="h6" gutterBottom>{fixture.home.team_name}</Typography>
                                                <Box sx={{ mb: 1 }}>
                                                    <Typography variant="body2">Home Performance: {(fixture.home.home_performance * 100).toFixed(2)}%</Typography>
                                                    <ColorBar value={fixture.home.home_performance} />
                                                </Box>
                                                <Box sx={{ mb: 1 }}>
                                                    <Typography variant="body2">Overall Performance: {(fixture.home.performance * 100).toFixed(2)}%</Typography>
                                                    <ColorBar value={fixture.home.performance} />
                                                </Box>
                                                <Box sx={{ mb: 1 }}>
                                                    <Typography variant="body2">Probability to Score: {(fixture.home.probability_to_score * 100).toFixed(2)}%</Typography>
                                                    <ColorBar value={fixture.home.probability_to_score} />
                                                </Box>
                                                <Box sx={{ mb: 1 }}>
                                                    <Typography variant="body2">Likelihood of Predicted Goals: {(fixture.home.likelihood * 100).toFixed(2)}%</Typography>
                                                    <ColorBar value={fixture.home.likelihood} />
                                                </Box>
                                                {renderNextFixture(fixture.home)}
                                            </Box>
                                            <Box sx={{ flex: 1 }}>
                                                <Typography variant="h6" gutterBottom>{fixture.away.team_name}</Typography>
                                                <Box sx={{ mb: 1 }}>
                                                    <Typography variant="body2">Away Performance: {(fixture.away.away_performance * 100).toFixed(2)}%</Typography>
                                                    <ColorBar value={fixture.away.away_performance} />
                                                </Box>
                                                <Box sx={{ mb: 1 }}>
                                                    <Typography variant="body2">Overall Performance: {(fixture.away.performance * 100).toFixed(2)}%</Typography>
                                                    <ColorBar value={fixture.away.performance} />
                                                </Box>
                                                <Box sx={{ mb: 1 }}>
                                                    <Typography variant="body2">Probability to Score: {(fixture.away.probability_to_score * 100).toFixed(2)}%</Typography>
                                                    <ColorBar value={fixture.away.probability_to_score} />
                                                </Box>
                                                <Box sx={{ mb: 1 }}>
                                                    <Typography variant="body2">Likelihood of Predicted Goals: {(fixture.away.likelihood * 100).toFixed(2)}%</Typography>
                                                    <ColorBar value={fixture.away.likelihood} />
                                                </Box>
                                                {renderNextFixture(fixture.away)}
                                            </Box>
                                        </Box>
                                        <Box sx={{ mb: 2 }}>
                                            <Typography variant="body2" gutterBottom>Both Teams to Score:</Typography>
                                            <BTTSGraph value={calculateBTTS(fixture.home, fixture.away)} />
                                        </Box>
                                        <Box sx={{ mb: 2 }}>
                                            <Typography variant="body2" gutterBottom>Performance Difference:</Typography>
                                            <PerformanceDifferenceGraph value={calculatePerformanceDifference(fixture.home, fixture.away)} />
                                        </Box>
                                    </Box>
                                )}
                                {activeTab === 1 && (
                                    <Box sx={{ display: 'flex', gap: 2 }}>
                                        <Box sx={{ flex: 1 }}>
                                            <Typography variant="h6" gutterBottom>Last 5 Fixtures - {fixture.home.team_name}</Typography>
                                            {renderPastFixtures(fixture.home.past_fixtures, fixture.home.team_name)}
                                        </Box>
                                        <Box sx={{ flex: 1 }}>
                                            <Typography variant="h6" gutterBottom>Last 5 Fixtures - {fixture.away.team_name}</Typography>
                                            {renderPastFixtures(fixture.away.past_fixtures, fixture.away.team_name)}
                                        </Box>
                                    </Box>
                                )}
                                {activeTab === 2 && (
                                    <Box>
                                        <Typography variant="h6" gutterBottom>Head to Head</Typography>
                                        {renderPastFixtures(fixture.h2h)}
                                    </Box>
                                )}
                            </CardContent>
                        )}
                    </Card>
                )) : (
                    <Typography>No fixtures available</Typography>
                )}
            </Box>
        </Box>
    );
}                                    
